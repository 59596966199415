<template>
	<div class="animated fadeIn">
		<b-card>
			<b-card-title><i class="fa fa-pie-chart"></i> Asset Accountability</b-card-title>
			<b-card-sub-title>Handles the list of asset pool count for all asset types accounted to a company</b-card-sub-title>
			<b-container fluid class="mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
				<b-card>
					<b-row>
						<b-col md="12" sm="12" class="mt-1 mb-2">
							<b>FILTER OPTIONS</b>
						</b-col>
					</b-row>

					<b-row no-gutters>
						<b-col lg="4" md="6" sm="12" class="mr-2">
							<b-form-group label="Company">
								<v-select class="style-chooser" label="text" :options="companyOptions"
									:reduce="(company) => company.value" v-model="filterBy.company">
									<template v-slot:no-options="{ search, searching }">
										<template v-if="searching">
											No results found for
											<em>
												<strong>{{ search }}</strong>
											</em>
										</template>
										<em :style="{ opacity: 0.5 }" v-else>
											Start typing to search for a company
										</em>
									</template>
								</v-select>
							</b-form-group>
						</b-col>

						<b-col lg="4" md="6" sm="12" class="mr-2">
							<b-form-group label="Asset Type">
								<v-select class="style-chooser" label="text" :options="assetTypeOptions"
									:reduce="(assetType) => assetType.value" v-model="filterBy.assetType">
									<template v-slot:no-options="{ search, searching }">
										<template v-if="searching">
											No results found for
											<em>
												<strong>{{ search }}</strong>
											</em>
										</template>
										<em :style="{ opacity: 0.5 }" v-else>
											Start typing to search for status
										</em>
									</template>
								</v-select>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row no-gutters>
						<b-col sm="12">
							<b-button class="mr-1" variant="success" @click="onFilterRequest">
								Generate
							</b-button>
							<b-button class="mr-1" variant="primary" @click="resetFilters">
								Reset
							</b-button>
						</b-col>
					</b-row>
				</b-card>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
									Export Asset Accountability in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
									Export Asset Accountability to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>

					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
					:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
					:sort-direction="sortDirection" responsive>

					<template v-slot:cell(storedCount)="row">
						{{ row.item.storedCount ? row.item.storedCount.toLocaleString() : 0 }}
					</template>

					<template v-slot:cell(inTransitCount)="row">
						{{ row.item.inTransitCount ? row.item.inTransitCount.toLocaleString() : 0 }}
					</template>

					<template v-slot:cell(lostCount)="row">
						{{ row.item.lostCount ? row.item.lostCount.toLocaleString() : 0 }}
					</template>

					<template v-slot:cell(inRepairCount)="row">
						{{ row.item.inRepairCount ? row.item.inRepairCount.toLocaleString() : 0 }}
					</template>

					<template v-slot:cell(disposedCount)="row">
						{{ row.item.disposedCount ? row.item.disposedCount.toLocaleString() : 0 }}
					</template>

					<template v-slot:cell(totalCount)="row">
						{{ row.item.totalCount ? row.item.totalCount.toLocaleString() : 0 }}
					</template>

					<template v-slot:cell(actions)="row">
						<span class="text-nowrap">
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="success"
								@click.stop="row.toggleDetails" class="mr-1">
								<em class="fa fa-eye-slash" v-if="row.detailsShowing"></em>
								<em class="fa fa-eye" v-else></em>
							</b-button>
						</span>
					</template>

					<template slot="row-details" slot-scope="row">
						<AssetAccountabilityDetailsView :companyAssetPool="row.item" />
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="totalDisplay">Total: {{ totalRows }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</b-container>
		</b-card>
	</div>
</template>

<script>
// Component
import AssetAccountabilityDetailsView from '@/views/reports/assetAccountability/AssetAccountabilityDetailsView';

// Util
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { DateUtil } from '@/utils/dateutil';

// API
import assetApi from '@/api/assetApi';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
	name: 'asset-accountability',
	components: {
		AssetAccountabilityDetailsView,
		Loading,
		JsonExcel,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'company',
					label: 'Company',
					sortable: true,
				},
				{
					key: 'assetType',
					label: 'Asset Type',
					sortable: true,
				},
				{
					key: 'storedCount',
					label: 'Stored',
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'inTransitCount',
					label: 'In-Transit',
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'lostCount',
					label: 'For Recon',
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'inRepairCount',
					label: 'In-Repair',
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'disposedCount',
					label: 'Disposed',
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'totalCount',
					label: 'Total',
					sortable: true,
					class: 'text-right'
				},
				'actions',
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			companyOptions: [],
			assetTypeOptions: [],

			allCompaniesObj: {},
			allAsetTypesObj: {},
			allCompanyAssetPoolsObj: {},

			defaultFilterBy: {
				company: Object.assign({}, config.companyDefaultValue),
				assetType: Object.assign({}, config.assetTypeDefaultValue),
			},
			filterBy: {
				company: Object.assign({}, config.companyDefaultValue),
				assetType: Object.assign({}, config.assetTypeDefaultValue),
			},
			prevFilter: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				Company: 'company',
				'Asset Type': 'assetType',
				Total: 'totalCount',
				Stored: 'storedCount',
				'In-Transit': 'inTransitCount',
				'For Recon': 'lostCount',
				'In-Repair': 'inRepairCount',
				Disposed: 'disposedCount',
				Notes: 'notes',
				'Date Created': 'Date Created',
				'Date Updated': 'Date Updated',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return (
				'AssetAccountability-' +
				DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp)
			);
		},
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Don't initiate data retrieval when the account is not authenticated
				if (!this.$store.getters.isAuthenticated) {
					return;
				}

				// show loading indicator
				this.isLoading = true;

				// Load initial data
				this.allCompaniesObj = this.$store.getters.companies;
				this.companyOptions = DropDownItemsUtil.retrieveCompanyItems(
					this.allCompaniesObj, this.loggedUserCompany
				);

				if (!this.isSuperAdmin) {
					this.filterBy.company = DropDownItemsUtil.getCompanyItem(
						this.loggedUserCompany
					);
					this.defaultFilterBy.company = DropDownItemsUtil.getCompanyItem(
						this.loggedUserCompany
					);
				}

				this.allAssetTypesObj = this.$store.getters.assetTypes;
				this.assetTypeOptions = DropDownItemsUtil.retrieveAssetTypeItems(
					this.allAssetTypesObj
				);

				await this.retrieveData();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);
	},
	methods: {
		updateTable(companyAssetPoolsObj) {
			if (!_.isEmpty(companyAssetPoolsObj)) {
				this.allCompanyAssetPoolsObj[companyAssetPoolsObj.id] =
					companyAssetPoolsObj;
				this.filterTableContent();
			}
		},
		filterTableContent() {
			let filteredObj = Object.assign({}, this.allCompanyAssetPoolsObj);

			_.forEach(this.allCompanyAssetPoolsObj, (companyAssetPool, id) => {
				if (
					this.filterBy.company.id !== null &&
					this.filterBy.company.id !== companyAssetPool.companyId
				) {
					delete filteredObj[id];
				}

				if (
					this.filterBy.assetType.id !== null &&
					this.filterBy.assetType.id !== companyAssetPool.assetTypeId
				) {
					delete filteredObj[id];
				}
			});

			this.processCompanyAssetPool(filteredObj);
		},

		async retrieveData() {
			try {
				// Show loader
				this.isLoading = true;

				let filter = Object.assign({}, this.filterBy);
				filter.companyId = this.loggedUserCompany.id;

				if (this.isSuperAdmin) {
					const { data } = await assetApi.getCompanyAssetPools(
						this.loggedUser.id,
						config.view.ADMIN,
						filter
					);

					this.allCompanyAssetPoolsObj = data.companyAssetPools;
				} else {
					const { data } = await assetApi.getCompanyAssetPools(
						this.loggedUser.id,
						config.view.COMPANY,
						filter
					);

					this.allCompanyAssetPoolsObj = data.companyAssetPools;
				}

				this.processCompanyAssetPool(this.allCompanyAssetPoolsObj);
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		processCompanyAssetPool(companyAssetPools) {
			this.allCompanyAssetPoolsObj = companyAssetPools;

			this.items = Object.values(this.allCompanyAssetPoolsObj);
			this.items.forEach((item) => {
				item['Date Created'] = this.getFormattedDateWithTime(item.dateCreated);
				item['Date Updated'] = this.getFormattedDateWithTime(item.dateUpdated);
			});
			this.items = _.sortBy(this.items, ['company']);
			this.totalRows = this.items.length;

			// update cache
			this.$store.dispatch(
				'setAllCompanyAssetPools',
				this.allCompanyAssetPoolsObj
			);
		},

		onFilterRequest() {
			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},

		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = Object.assign({}, this.defaultFilterBy);
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},

		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},
};
</script>

<style scoped>
.filter {
	background-color: white;
	border-color: #122c91;
}

.filter:hover {
	background-color: #122c91;
	color: white;
}
</style>
