<template>
	<div>
		<b-card no-body>
			<b-tabs card>
				<b-tab title="Primary Information" active>
					<b-card>
						<b-form-group
							label="Company:"
							label-cols-sm="2"
							label-class="font-weight-bold pt-0"
							label-align-sm="right"
						>
							{{ showValue(companyAssetPool.company) }}
						</b-form-group>

						<b-form-group
							label="Asset Type:"
							label-cols-sm="2"
							label-class="font-weight-bold pt-0"
							label-align-sm="right"
						>
							{{ showValue(companyAssetPool.assetType) }}
						</b-form-group>

						<b-form-group
							label="Date Created:"
							label-cols-sm="2"
							label-class="font-weight-bold pt-0"
							label-align-sm="right"
						>
							{{ showFormattedDate(companyAssetPool.dateCreated) }}
						</b-form-group>

						<b-form-group
							label="Date Updated:"
							label-cols-sm="2"
							label-class="font-weight-bold pt-0"
							label-align-sm="right"
						>
							{{ showFormattedDate(companyAssetPool.dateUpdated) }}
						</b-form-group>

						<b-form-group
							label="Updated By:"
							label-cols-sm="2"
							label-class="font-weight-bold pt-0"
							label-align-sm="right"
						>
							{{ showValue(companyAssetPool.updatedBy) }}
						</b-form-group>
					</b-card>
				</b-tab>

				<b-tab title="Details">
					<b-card>
						<b-row no-gutters>
							<b-col lg="3" md="5" sm="12" class="mr-2">
								<b-card no-body class="bg-light">
									<b-card-body class="pb-0">
										<h4 class="mb-3">
											{{ showNumber(companyAssetPool.totalCount) }}
										</h4>
										<p class="stat-label">Total</p>
									</b-card-body>
								</b-card>
							</b-col>

							<b-col lg="3" md="5" sm="12" class="mr-2">
								<b-card no-body class="bg-primary">
									<b-card-body class="pb-0">
										<h4 class="mb-3">
											{{ showNumber(companyAssetPool.storedCount) }}
										</h4>
										<p class="stat-label">Stored</p>
									</b-card-body>
								</b-card>
							</b-col>

							<b-col lg="3" md="5" sm="12" class="mr-2">
								<b-card no-body class="bg-success">
									<b-card-body class="pb-0">
										<h4 class="mb-3">
											{{ showNumber(companyAssetPool.inTransitCount) }}
										</h4>
										<p class="stat-label">In-Transit</p>
									</b-card-body>
								</b-card>
							</b-col>

							<b-col lg="3" md="5" sm="12" class="mr-2">
								<b-card no-body class="bg-danger">
									<b-card-body class="pb-0">
										<h4 class="mb-3">
											{{ showNumber(companyAssetPool.lostCount) }}
										</h4>
										<p class="stat-label">For Recon</p>
									</b-card-body>
								</b-card>
							</b-col>

							<b-col lg="3" md="5" sm="12" class="mr-2">
								<b-card no-body class="bg-warning">
									<b-card-body class="pb-0">
										<h4 class="mb-3">
											{{ showNumber(companyAssetPool.inRepairCount) }}
										</h4>
										<p class="stat-label">In-Repair</p>
									</b-card-body>
								</b-card>
							</b-col>

							<b-col lg="3" md="5" sm="12" class="mr-2">
								<b-card no-body class="bg-dark">
									<b-card-body class="pb-0">
										<h4 class="mb-3">
											{{ showNumber(companyAssetPool.disposedCount) }}
										</h4>
										<p class="stat-label">Disposed</p>
									</b-card-body>
								</b-card>
							</b-col>
						</b-row>

						<b-row>
							<b-col>
								<span class="update-notes">
									<i class="icon-info"></i> &nbsp;
									{{ showValue(companyAssetPool.notes) }}
								</span>
							</b-col>
						</b-row>
					</b-card>
				</b-tab>

				<b-tab title="Change Log">
					<b-card>
						<b-table
							show-empty
							striped
							hover
							:items="items"
							:fields="fields"
							:current-page="currentPage"
							:per-page="perPage"
							:filter="filter"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							:sort-direction="sortDirection"
							responsive
						>
							<template v-slot:cell(dateCreated)="row">
								{{ showFormattedDate(row.item.dateCreated) }}
							</template>
							<template v-slot:cell(reasonForUpdate)="row">
								{{ row.item.new.notes ? row.item.new.notes : '-' }}
							</template>
						</b-table>

						<b-row>
							<b-col md="8" sm="12" class="my-1">
								<span class="totalDisplay">Total: {{ totalRows }}</span>
							</b-col>
							<b-col md="4" sm="12" class="my-1">
								<b-pagination
									align="right"
									:total-rows="totalRows"
									:per-page="perPage"
									v-model="currentPage"
									class="my-0"
								/>
							</b-col>
						</b-row>
					</b-card>
				</b-tab>
			</b-tabs>
		</b-card>
	</div>
</template>

<script>
// API & Database
import AuditTrailLogsDAO from '@/database/auditTrailLogs';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'asset-accountability-details-view',
	components: {
		Loading,
	},
	props: {
		companyAssetPool: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'createdBy',
					label: 'Created By',
					sortable: true,
				},
				{
					key: 'dateCreated',
					label: 'Date Created',
					sortable: true,
				},
				{
					key: 'reasonForUpdate',
					label: 'Reasons for Update',
				},
			],

			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},

	async mounted() {
		let companyAssetPoolId = this.companyAssetPool.id;
		this.retrieveChangeLog(companyAssetPoolId);
	},

	methods: {
		async retrieveChangeLog(companyAssetPoolId) {
			if (companyAssetPoolId) {
				let param = {
					collection: 'companyAssetPools',
					id: companyAssetPoolId,
				};

				let results = await AuditTrailLogsDAO.getAuditTrailLogs(param);
				this.items = Object.values(results);
				this.totalRows = this.items.length;
			}
		},

		showNumber(value) {
			if (value) {
				return value;
			}
			return 0;
		},

		showValue(value) {
			if (!_.isEmpty(value)) {
				return value;
			}
			return '-';
		},

		showFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},
};
</script>

<style scoped>
.update-notes {
	font-style: italic;
}
</style>
